.footer-component {
  padding-top: 20px;
  padding-bottom: 15px;
  border-top: 1px solid rgba($grey, .8);
  color: rgba($white, .56);
  position: relative;

  .footer-component-list {
    margin-bottom: 0;

    .footer-component-list-item {
      @include fontSize(14, 14);
      display: block;
      margin-bottom: 5px;

      @include min-screen($small) {
        display: inline-block;
        padding-right: 15px;
        margin-bottom: 0;
        position: relative;

        &::after {
          content: '';
          display: inline-block;
          background-color: rgba(0, 154, 255, .5);
          width: 1px;
          height: 8px;
          position: absolute;
          right: 0;
          top: 3px;
        }
      }
      
      br {
        @include min-screen($phone) {
          display: none;
        }
      }

      + .footer-component-list-item {
        padding-right: 0;
        border-right: 0;

        @include min-screen($small) {
          padding-left: 11px;

          &::after {
            display: none;
          }
        }
      }
    }
  }
}

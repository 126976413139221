@import 'reset';
@import 'fonts';
@import 'variables';
@import 'mixins';
@import 'typography';
@import 'bootstrap';
@import 'components';

html {
  font-size: 16px;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: $black;
  font-family: $font;
  color: $white;
}

img {
  max-width: 100%;
  height: auto;
}

a,
a:hover {
  text-decoration: none;
}

.right-text-mobile-up {
  @include min-screen($mobile) {
    text-align: right;
  }
}

.video-component {
  position: fixed;
  width: 100%;
  height: 100%;

  video {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: auto;
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(225deg, rgba(2, 7, 30, .72) 0%, #020619 100%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $white;
}

h1 {
  @include fontSize(32, 36);
  font-weight: 400;
  font-family: 'eurostile';

  @include min-screen($small) {
    @include fontSize(64, 72);
  }
}

p {
  @include fontSize(20, 24);
  display: block;
  color: $white;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

strong {
  font-weight: 600;
}

.cta {
  @include fontSize(18, 18);
  display: inline-block;
  color: $white;
  font-weight: 700;
  background-image: linear-gradient(135deg, $blue 0%, $blue2 100%);
  border-radius: 30px;
  box-shadow: 0 16px 56px 0 rgba(0, 154, 255, .24);
  padding: 16px 30px;
  opacity: 1;
  transition: opacity .4s;

  @include min-screen($phone) {
    padding-left: 45px;
    padding-right: 45px;
  }

  &:hover,
  &:focus {
    color: $white;
    opacity: .75;
  }
}

.cta-transparent {
  @extend .cta;
  background-image: none;
  background-color: rgba(22, 23, 36, .64);
  border: 2px solid $blue;
  padding-top: 14px;
  padding-bottom: 14px;

  .cta + &  {
    margin-left: 20px;
  }
}

.header-component {
  padding-top: 10px;

  @include min-screen($small) {
    padding-top: 60px;
  }

  .header-component-logo {
    height: 42px;
  }
}

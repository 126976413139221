.hero-component {
  padding-top: 30px;
  padding-bottom: 30px;

  .hero-component-title {
    margin-bottom: 20px;

    @include min-screen($small) {
      margin-bottom: 60px;
    }
  }

  .hero-component-wrapper {
    @include min-screen($small) {
      margin-bottom: 60px;
    }
  }

  .hero-component-icon {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .hero-component-icon-wrapper {
      width: 96px;
      height: 96px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid $grey2;
      background-color: rgba(22, 23, 36, 1);
    }

    .hero-component-icon-text {
      margin-left: 15px;
      margin-bottom: 0;
    }
  }
}

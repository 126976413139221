@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie); //IE8
}

@mixin screen($resMin, $resMax) {
  @media screen and (min-width: $resMin) and (max-width: $resMax) {
    @content;
  }
}

@mixin max-screen($res) {
  @media screen and (max-width: $res) {
    @content;
  }
}

@mixin min-screen($res) {
  @media screen and (min-width: $res) {
    @content;
  }
}

@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

@mixin fontSize($fSize, $lineHeight: null) {
  $pxValues: '';
  $remValues: '';

  $pxValues: $fSize;
  $remValues: ($fSize / 16);

  #{font-size}: $pxValues + px;
  #{font-size}: $remValues + rem;

  @if ($lineHeight != null) {
    line-height: $lineHeight + px;
  }
}


@mixin linearGradient($top, $bottom) {
  background: $top; /* Old browsers */
  background: -moz-linear-gradient(top, $top 0%, $bottom 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, $top), color-stop(100%, $bottom)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, $top 0%, $bottom 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, $top 0%, $bottom 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $top 0%, $bottom 100%); /* IE10+ */
  background: linear-gradient(to bottom, $top 0%, $bottom 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#000000', GradientType=0); /* IE6-9 */
}

@mixin linearGradientLeft($left, $right) {
  background: $left; /* Old browsers */
  background: -moz-linear-gradient(left, $left 0%, $right 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left, right, color-sleft(0%, $left), color-sleft(100%, $right)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(left, $left 0%, $right 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(left, $left 0%, $right 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(left, $left 0%, $right 100%); /* IE10+ */
  background: linear-gradient(to right, $left 0%, $right 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#000000', GradientType=0); /* IE6-9 */
}

@mixin truncateText($overflow: ellipsis) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: $overflow; // values are: clip, ellipsis, or a string
}

@mixin button($color, $border, $fontSize, $bgColor, $radius) {
  @include fontSize($fontSize);
  line-height: 1;
  display: inline-block;
  color: $color;
  border: $border solid;
  background-color: $bgColor;
  border-radius: $radius;
  text-transform: uppercase;
  transition: background-color $cubic, color $cubic;
}

@mixin button-hover($color, $bgColor) {
  color: $color;
  background-color: $bgColor;
}

@function gridColumn($amount) {
  @return (100% / 12) * $amount;
}

@font-face {
  font-family:"eurostile";
  src:url("https://use.typekit.net/af/2d8a3b/00000000000000003b9b2159/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/2d8a3b/00000000000000003b9b2159/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/2d8a3b/00000000000000003b9b2159/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style:normal;
  font-weight:400;
}

bold, strong {
  font-family: 'Roboto';
  font-weight: 700;
}
em, italic {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/roboto-bold-webfont.eot');
  src: url('assets/fonts/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/roboto-bold-webfont.woff') format('woff'),
  url('assets/fonts/roboto-bold-webfont.ttf') format('truetype'),
  url('assets/fonts/roboto-bold-webfont.svg#robotobold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/roboto-italic-webfont.eot');
  src: url('assets/fonts/roboto-italic-webfont.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/roboto-italic-webfont.woff') format('woff'),
  url('assets/fonts/roboto-italic-webfont.ttf') format('truetype'),
  url('assets/fonts/roboto-italic-webfont.svg#robotoitalic') format('svg');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/roboto-bolditalic-webfont.eot');
  src: url('assets/fonts/roboto-bolditalic-webfont.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/roboto-bolditalic-webfont.woff') format('woff'),
  url('assets/fonts/roboto-bolditalic-webfont.ttf') format('truetype'),
  url('assets/fonts/roboto-bolditalic-webfont.svg#robotobold_italic') format('svg');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/roboto-regular-webfont.eot');
  src: url('assets/fonts/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/roboto-regular-webfont.woff') format('woff'),
  url('assets/fonts/roboto-regular-webfont.ttf') format('truetype'),
  url('assets/fonts/roboto-regular-webfont.svg#robotoregular') format('svg');
  font-weight: 400;
  font-style: normal;
}
